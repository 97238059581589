import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`Happy Derby Day!  Today is our monthly free Partner Workout at 9:00
& 10:00am so bring a friend or come alone and we’ll pair you up with
someone.  `}</strong></p>
    <p><strong parentName="p">{`We’ll have just one hour of open gym so we’ll close at 12:00 today.`}</strong></p>
    <p>{`Rest Day.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      